<template>
  <section class="background--views">
    <v-container>
      <component
        :is="component"
        :isEdit="isEdit"
        :item="item"
        :clientDetailOrigin="clientDetailOrigin"
        :updated="updated"
        @changeComponent="changeComponent"
        @edit="edit"
        @delete="deleted"
        @detail="detail"
        @updatedComplete="updated = false"
      ></component>
    </v-container>

    <modal-delete
      @cancel="dialogDelete = false"
      @delete="deleteClient"
      :dialogDelete="dialogDelete"
      :body="body"
    ></modal-delete>
  </section>
</template>

<script>
// Domains
import Client from "../domain/Clients";


export default {
  name: "Clients",
  components: {
    ClientsTable: () => import("../components/clients/ClientsTable"),
    ClientNew: () => import("../components/clients/ClientNew"),
    ClientDetail: () => import("../components/clients/ClientDetail"),
    ModalDelete: () => import("../components/base/ModalDelete"),
  },
  data: () => ({
    component: "ClientsTable",
    isEdit: false,
    dialogDelete: false,
    item: {},
    clientDetailOrigin: {},
    body: {
      title: "Eliminar cliente",
      type: "Cliente",
      question: "¿Desea eliminar al cliente?",
    },
    clientDelete: "",
    updated: false,
  }),
  methods: {
    changeComponent(component) {
      this.isEdit = false;
      this.component = component;
    },
    async edit(id) {
      try {
        const user = await Client.findById(id);
        this.clientDetailOrigin = user;
        this.clientDetailOrigin.id = id;
        this.isEdit = true;
        this.component = "ClientNew";
      } catch (error) {
        console.warn(error);
      }
    },
    deleted(item) {
      this.clientDelete = item._id;
      this.body.question = `¿Esta seguro que desea eliminar al cliente ${item.name}?`;
      this.dialogDelete = true;
    },
    async deleteClient() {
      try {
        await Client.delete(this.clientDelete);
        this.updated = true;
        this.dialogDelete = false;
        this.component = "ClientsTable";
      } catch (error) {
        console.warn(error);
      }
    },
    async detail(id) {
      try {
        this.clientDetailOrigin = await Client.findById(id);
        this.clientDetailOrigin.id = id;
        this.component = "ClientDetail";
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
</script>